import React, { useState, useEffect } from 'react';
import { signInWithPopup, signOut } from 'firebase/auth';
import { auth, googleProvider } from './firebase';
import QuestionsList from './components/QuestionsList';
import Content from './components/Content';
import GoogleSignInButton from './components/GoogleSignInButton';
import Header from './components/Header';

function App() {
  const [user, setUser] = useState({});
  const eventId = 'H9OljB4E4fajxsifS2fa';
  const leaderboardId = 'V4RzUs3erszfQynrWH9p';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      setUser(user);
    } catch (error) {
      console.log('Login Failed:', error);
    }
  };

  // const logOut = () => {
  //   signOut(auth)
  //     .then(() => {
  //       setUser(null);
  //     })
  //     .catch((error) => {
  //       console.error('Error logging out:', error);
  //     });
  // };

  return (
    <div className='text-white'>
      {user ? (
        <Header user={user} leaderboardId={leaderboardId} />
      ) : (
        <div className='flex flex-col items-center pt-4 gap-2 bg-dark1'>
          <h3 className='text-center font-bold text-[18px]'>
            Sign In With Your Google Account to Play
          </h3>
          <GoogleSignInButton onClick={login} />
        </div>
      )}

      <Content />
      <QuestionsList eventId={eventId} user={user} />
      {/* {user && <div className='flex w-full items-end justify-end'>
        <button className='border rounded-full px-4 py-1' onClick={logOut}>
          Log out
        </button>
      </div>} */}
    </div>
  );
}

export default App;
