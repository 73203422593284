import React from "react";

function Content() {
  return (
    <div className="bg-black">
      <div className="p-6 rounded-lg">
        <div className="flex justify-center">
          <img
            src="https://superstreaming.gg/wp-content/uploads/2024/06/gbs_banner.png"
            alt="Event Banner"
            className="rounded-lg mb-4"
          />
        </div>
        <div className="text-center text-white">
          <h1
            className="text-[34px] font-[900] mb-2"
            style={{ lineHeight: "102%", letterSpacing: "0.68px" }}
          >
            TAYNE DE VILLIERS vs KNUCKLEHEAD WARRIOR
          </h1>
          <p
            className="text-[14px] font-[600]"
            style={{ lineHeight: "110%", letterSpacing: "0.28px" }}
          >
            Saturday June 1st
          </p>
          <p
            className="text-[14px] font-[600]"
            style={{ lineHeight: "110%", letterSpacing: "0.28px" }}
          >
            8PM IST
          </p>
          <div
            className="flex h-1 w-2/3 mx-auto my-3"
            style={{ backgroundColor: "#40D700" }}
          ></div>
          <p
            className="text-[17px] font-[600] mb-6"
            style={{ lineHeight: "126%", letterSpacing: "0.34px" }}
          >
            Play along for FREE and answer prediction, trivia and recall
            questions during match day for a chance to win!
          </p>
          <h2
            className="text-[22px] font-[900] mb-4"
            style={{ color: "#40D700", letterSpacing: "0.44px" }}
          >
            Place Your Predictions!
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Content;
