import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDLdDToFBWxwQdput0ManNrKDqNFI-dPSE",
  authDomain: "superstreaming-gg.firebaseapp.com",
  projectId: "superstreaming-gg",
  storageBucket: "superstreaming-gg.appspot.com",
  messagingSenderId: "319805469731",
  appId: "1:319805469731:web:87f9d799fb2942edcbad96"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app);

export { db, functions, auth, googleProvider };