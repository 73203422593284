import { signInWithPopup } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { auth, functions, googleProvider } from "../firebase";

function Segments({ eventId, segments }) {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      setUser(user);
    } catch (error) {
      console.log("Login Failed:", error);
    }
  };

  const handleOptionChange = (questionId, option) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: option,
    });
  };

  const handleSubmit = async (questionId) => {
    if (!user) {
      console.log("User is not logged in");
      login();
      return;
    }

    const answer = selectedAnswers[questionId];
    if (answer) {
      const putAnswerFunction = httpsCallable(functions, "putAnswer");
      try {
        const result = await putAnswerFunction({
          eventId,
          questionId,
          answer,
        });
        console.log("Answer submitted successfully", { questionId, answer });
        return result.data;
      } catch (error) {
        console.error("Error submitting answer:", error);
      }
    } else {
      console.error("No answer selected");
    }
  };

  return (
    <div className="container mx-auto text-white">
      <div className=" flex flex-col gap-3">
        {segments.map((segment, i) => (
          <div key={`${segment.id}-${i}`} className="px-1 mb-4">
            <div className="flex flex-col gap-4">
              <h3
                className="text-center text-[18px] font-[900]"
                style={{ letterSpacing: "0.44px" }}
              >
                {segment.name}
              </h3>
              {segment.questions.map((question) => (
                <div
                  key={question.id}
                  className="flex gap-6 items-start p-4 rounded mb-4 bg-gradient-to-t to-dark2 from-basedark"
                >
                  <div className="flex-1 w-1/2">
                    <h3 className="text-[18px] font-medium">{question.text}</h3>
                    {question.answer && (
                      <p className="underline">{question.answer}</p>
                    )}
                  </div>
                  <div className="flex-1 w-1/2">
                    {question.options.map((option) => (
                      <div
                        key={option.id}
                        className={twMerge(
                          "flex items-center gap-2 mb-2",
                          user ? "opacity-100" : "opacity-50"
                        )}
                      >
                        <input
                          type="radio"
                          id={option.id}
                          name={`question-${question.id}`}
                          value={option}
                          className={`form-radio h-5 w-5`}
                          onChange={() =>
                            handleOptionChange(question.id, option)
                          }
                          disabled={!user || question.answer}
                        />
                        <label htmlFor={option.id} className="text-[13px]">
                          {option}
                        </label>
                      </div>
                    ))}
                    <button
                      className="mt-4 px-4 py-2 text-white rounded w-full bg-gradient-to-t to-dark2 from-basedark border border-gray-600 text-[16px] font-medium"
                      onClick={() => handleSubmit(question.id)}
                      disabled={question.answer}
                    >
                      {user ? "Submit" : "Sign In"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Segments;
