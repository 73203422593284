type Props = {
  className?: string,
};

export function IconGoogle(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M19.0798 8.78631H18.375V8.75H10.5V12.25H15.4451C14.7236 14.2874 12.7851 15.75 10.5 15.75C7.60069 15.75 5.25 13.3993 5.25 10.5C5.25 7.60069 7.60069 5.25 10.5 5.25C11.8383 5.25 13.0559 5.75487 13.9829 6.57956L16.4579 4.10462C14.8951 2.64819 12.8048 1.75 10.5 1.75C5.66781 1.75 1.75 5.66781 1.75 10.5C1.75 15.3322 5.66781 19.25 10.5 19.25C15.3322 19.25 19.25 15.3322 19.25 10.5C19.25 9.91331 19.1896 9.34063 19.0798 8.78631Z"
        fill="#FFC107"
      />
      <path
        d="M2.75885 6.42731L5.63366 8.53562C6.41154 6.60975 8.29541 5.25 10.5 5.25C11.8383 5.25 13.0559 5.75487 13.9829 6.57956L16.4578 4.10462C14.8951 2.64819 12.8047 1.75 10.5 1.75C7.1391 1.75 4.22447 3.64744 2.75885 6.42731Z"
        fill="#FF3D00"
      />
      <path
        d="M10.5 19.2498C12.7601 19.2498 14.8138 18.3849 16.3665 16.9783L13.6583 14.6867C12.7798 15.3521 11.6878 15.7498 10.5 15.7498C8.22414 15.7498 6.2917 14.2986 5.5637 12.2734L2.71033 14.4719C4.15845 17.3056 7.09933 19.2498 10.5 19.2498Z"
        fill="#4CAF50"
      />
      <path
        d="M19.0798 8.64666H18.375V8.61035H10.5V12.1104H15.4451C15.0986 13.089 14.469 13.933 13.657 14.5477L13.6583 14.5468L16.3664 16.8384C16.1748 17.0125 19.25 14.7354 19.25 10.3604C19.25 9.77366 19.1896 9.20098 19.0798 8.64666Z"
        fill="#1976D2"
      />
    </svg>
  );
}
