import React from "react";

import { IconGoogle } from "./IconGoogle";

function GoogleSignInButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="flex items-center pl-1 pr-3 py-1 mb-4 bg-transparent text-white border border-gray-300 rounded-full shadow-md hover:bg-gray-100 focus:outline-none"
    >
      <IconGoogle className="h-6 absolute-center-y mr-2" />
      <span className="text-[13px] font-medium">Sign In With Google</span>
    </button>
  );
}

export default GoogleSignInButton;
