import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import Segments from "./Segments";

function QuestionsList({ eventId, user }) {
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    if (!user) {
      console.log("User is not logged in");
      return;
    }
  }, [user]);

  useEffect(() => {
    console.log(`Fetching segments for event: ${eventId}`);
    const segmentsRef = doc(db, "events", eventId);
    onSnapshot(segmentsRef, (segmentsSnapshot) => {
      console.log("Segments snapshot:", segmentsSnapshot);
      if (segmentsSnapshot.empty) {
        console.log("No segments found for the event.");
        return;
      }

      const allQuestions = [];
      const allSegments = [];

      const data = segmentsSnapshot.data();

      const segments = data.segments;
      let logged = false;
      for (const segment of segments) {
        allSegments.push(segment);
        allQuestions.push(segment.questions);
        if (!logged) {
          logged = true;
          console.log("Questions:", segment.questions);
        }
      }

      setSegments(allSegments);
    });
  }, [eventId]);

  return (
    <div className="bg-black">
      <Segments segments={segments} eventId={eventId} />
    </div>
  );
}

export default QuestionsList;
