import {
  faCheckCircle,
  faTimesCircle,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import HeaderCard from "./HeaderCard";

import {
  collection,
  doc,
  getCountFromServer,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

const Header = ({ user, leaderboardId }) => {
  const [position, setPosition] = useState(0);
  const [coins, setCoins] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);

  useEffect(() => {
    onSnapshot(
      doc(db, "playerScores", `${leaderboardId}-${user.id}`),
      (snapshot) => {
        const playerScore = snapshot.data();
        console.log("Player Score:", playerScore);
        const coinsEarned = playerScore?.coinsEarned ?? 0;
        setCoins(coinsEarned);

        const gp = playerScore?.gamesPlayed ?? 0;
        setGamesPlayed(gp);

        if (coinsEarned === 0) {
          setPosition(10);
        } else {
          getCountFromServer(
            query(
              collection(db, "playerScores"),
              where("leaderboardId", "==", leaderboardId),
              where("coinsEarned", ">", coinsEarned)
            )
          ).then((docs) => {
            const p = docs.data().count + 1;
            setPosition(p);
          });
        }
      }
    );
  }, [leaderboardId, user.id]);

  return (
    <div className="flex text-white">
      <HeaderCard>
        <div className="flex flex-col justify-center h-full items-center">
          <div className="rounded-full w-7 h-7 border border-main overflow-hidden">
            <img src={user.photoURL} alt="user" />
          </div>
          <div className="flex flex-col text-[13px] text-center">
            <span className="">SuperCoins</span>
            <span className="text-main">{coins}</span>
          </div>
        </div>
      </HeaderCard>
      <HeaderCard>
        <div className="flex flex-col justify-center h-full items-center">
          <FontAwesomeIcon icon={faTrophy} className="text-xl" />
          <span className="text-[13px]">{position}</span>
          <span className="text-[13px]">PLACE</span>
        </div>
      </HeaderCard>
      <HeaderCard>
        <div className="flex flex-col justify-center h-full items-center gap-2">
          <span className="text-[13px] font-medium text-center">
            QUESTIONS SETTLED
          </span>
          <div className="flex justify-center items-center gap-2">
            <div className="flex justify-center items-center bg-dark1 rounded h-[33px] w-[33px]">
              <p className="text-sm text-center">{gamesPlayed}</p>
            </div>
            <div className="flex justify-center items-center bg-dark1 rounded h-[33px] w-[33px]">
              <p className="text-sm text-center">27</p>
            </div>
          </div>
        </div>
      </HeaderCard>
      <HeaderCard>
        <div className="flex flex-col justify-center h-full items-center">
          <span className="text-[13px]">CORRECT</span>
          <div className="text-main text-[15px] flex justify-center items-center space-x-1 w-full">
            <FontAwesomeIcon icon={faCheckCircle} />
            <p className="text-white">0</p>
          </div>
          <span className="text-[13px]">INCORRECT</span>
          <div className="text-incorrect text-[15px] flex justify-center items-center space-x-1 w-full">
            <FontAwesomeIcon icon={faTimesCircle} />
            <p className="text-white">0</p>
          </div>
        </div>
      </HeaderCard>
    </div>
  );
};

export default Header;
